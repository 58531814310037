<template>
  <div>
    <v-form v-model="isValid" ref="form">
      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="First Name"
            v-model="data.first_name"
            v-bind="basic"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="Middle Name"
            v-model="data.middle_name"
            v-bind="basic"
          />
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="Last Name"
            v-model="data.last_name"
            v-bind="basic"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="Email"
            v-model="data.email"
            v-bind="basic"
            :rules="[rules.required, rules.email]"
          />
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="Mobile Number"
            v-model="data.phone"
            v-bind="basic"
            :rules="[rules.required, rules.number, rules.validPhoneNumber]"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Password"
            placeholder="Your Password"
            v-model="data.password"
            type="password"
            v-bind="basic"
            :rules="[rules.required]"
            autocomplete="null"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Confirm Password"
            placeholder="Password"
            v-model="data.password_confirmation"
            type="password"
            v-bind="basic"
            :rules="[rules.required, password_confirmation]"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="Address"
            v-model="data.address"
            v-bind="basic"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <fileUpload
            v-bind="basic"
            @file-upload-success="fileUploadSuccess"
            @delete-file="deleteFile"
            @max-files="maxFiles"
          />
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-btn
            color="primary"
            @click="submit"
            :disabled="!isValid"
            :loading="loading"
          >
            <span v-if="$props.id === 0">Add User</span>
            <span v-else>Update User</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import util from "@/util/util";
import fileUpload from "./file-chooser.vue";

export default {
  components: { fileUpload },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      password_confirmation: (value) =>
        value == this.data.password || "Passwords do not match.",
      rules: util.validation,
      isValid: false,
      basic: util.input.basic,
      data: {},
      delete: true,
      loading: false,
    };
  },
  methods: {
    submit() {
      let url = "user";
      this.loading = true;
      util
        .http({
          url: url,
          method: "POST",
          data: this.data,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            this.delete = false;
            util.notify(1, "User Added");
            this.$emit("closeDialog", true);
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    fileUploadSuccess(file, res) {
      this.data.profile_picture = res.data[0].url;
      this.delete = true;
    },
    maxFiles() {
      util.notify(0, "Can upload only one file");
    },
    deleteFile(file) {
      if (this.delete) {
        let url = this.data.profile_picture;
        util
          .http({
            url: "user-detail/file-remove",
            method: "post",
            data: { url, name: file.name },
          })
          .then((res) => {
            if (res.data.status == 0) {
              util.notify(0, res.data.errors[0]);
            } else {
              delete this.data.profile_picture;
              util.notify(1, "Removed");
            }
          });
      }
    },
  },
};
</script>
<style scoped>
/* .role-wrapper {
  padding-top: 30px;
} */
.item-button {
  background: #1d2b58 !important;
  color: #fff !important;
}
</style>
