<template>
  <div>
    <component-layout title="Users"
      ><template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-auto bg-btn" dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title
                class="text-decoration-none"
                v-ripple
                style="cursor: pointer"
                v-on:click="showAddModal()"
              >
                Add New User
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <vue-table
        itemName="user"
        :key="key"
        :url="table.url"
        :except="table.except"
        :sn="false"
        :headingTransformer="table.headingTransformer"
        :valueTransformer="table.valueTransformer"
        :html="table.html"
        :additionalColumns="table.additionalColumns"
        :additionalColumnsTransformer="table.additionalColumnsTransformer"
        :columnSortOrder="table.columnSortOrder"
      ></vue-table>
    </component-layout>
    <v-dialog v-model="dialog" width="600" :key="'b' + key">
      <v-card>
        <v-card-title class="title text-h5">
          <span> ADD USER </span>
        </v-card-title>
        <v-card-text class="py-5">
          <addUser @closeDialog="closeDialog" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" width="600" :key="'a' + key">
      <v-card>
        <v-card-title class="title text-h5">
          <span> EDIT USER </span>
        </v-card-title>
        <v-card-text class="py-5">
          <editUser :id="userId" @closeDialog="closeDialog" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import util from "@/util/util";
import VueTable from "../shared/vue-table.vue";
import ComponentLayout from "../component-layout.vue";
import addUser from "./create.vue";
import editUser from "./edit.vue";
import action from "./action";
export default {
  components: {
    VueTable,
    ComponentLayout,
    addUser,
    editUser,
  },
  data() {
    return {
      key: 0,
      userId: 0,
      dialog: false,
      editDialog: false,
      table: {
        url: "/user",
        except: [
          "id",
          "created_at",
          "updated_at",
          "deleted_at",
          "first_name",
          "middle_name",
          "last_name",
          "two_factor_code",
          "two_factor_expires_at",
          "email_verified_at",
          "sort",
          "details",
          "status",
        ],
        additionalColumns: ["phone", "address", "profile_picture", "Actions"],
        html: ["phone", "address", "profile_picture", "Actions"],
        columnSortOrder: {
          full_name: 1,
          phone: 2,
          email: 3,
          address: 4,
          profile_picture: 5,
          Actions: 6,
        },
        headingTransformer: function(val) {
          return util.camelCase(val);
        },
        additionalColumnsTransformer: function() {
          return {
            phone: (row) => {
              return [
                {
                  item: row.details ? row.details.phone : "",
                  handler: () => null,
                },
              ];
            },
            address: (row) => {
              return [
                {
                  item: row.details ? row.details.address : "",
                  handler: () => null,
                },
              ];
            },
            profile_picture: (row) => {
              return [
                {
                  item:
                    row.details && row.details.profile_picture
                      ? `<img src="${row.details.profile_picture}" width="150">`
                      : `<img src="https://i.ibb.co/9qNQyd2/download.png" width="150">`,
                  handler: () => null,
                },
              ];
            },
            Actions: (row) => {
              return [
                {
                  comp: action,
                  prop: {
                    item: row,
                    delete: {
                      params: { name: "role", id: row.id },
                    },
                  },
                },
              ];
            },
          };
        },
      },
    };
  },
  mounted() {
    util.event.$on("reload-resource", () => {
      this.key++;
    });
    util.event.$on("edit-user", (user) => {
      this.showEditModal(user.id);
    });
  },
  methods: {
    showAddModal() {
      this.userId = 0;
      this.editDialog = false;
      this.dialog = true;
      this.key++;
    },
    showEditModal(id) {
      this.userId = id;
      this.dialog = false;
      this.editDialog = true;
      this.key++;
    },
    closeDialog() {
      this.dialog = false;
      this.editDialog = false;
      this.key++;
    },
  },
};
</script>
