<template>
  <div>
    <v-simple-table>
      <!-- <thead>
        <tr>
          <th>Permission</th>
          <th>Description</th>
        </tr>
      </thead> -->
      <tbody>
        <tr v-for="p in $props.data.permissions" :key="p.id">
          <td>
            {{ p.name }}
          </td>
          <td>
            {{ p.description }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
};
</script>
