<template>
  <div>
    <v-form ref="form" lazy-validation>
      <div class="title text-h6 px-3">
        Edit Product
      </div>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="data.name"
              label="Name"
              placeholder="Enter Name"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              v-model="data.description"
              label="Description"
              placeholder="Enter Product Description"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="data.price"
              label="Price"
              placeholder="Enter Price"
              :rules="[rules.required, rules.number]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-select
              v-bind="basic"
              label="Status"
              v-model="data.status"
              :items="['Active', 'Inactive']"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="data.sort"
              label="Sort"
              placeholder="Enter Sort"
              :rules="[rules.required, rules.number]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="success"
              class="mr-4"
              v-on:click="submit"
              elevation="2"
              large
              block
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: {},
      rules: util.validation,
      basic: util.input.basic,
      loading: false,
    };
  },
  mounted() {
    this.fetchProduct();
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        util
          .http({
            url: `product/${this.$props.id}`,
            method: "put",
            data: this.data,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status == 1) {
              util.notify(1, "Product Updated");
              this.$emit("closeDialog");
              this.data = {};
            } else {
              util.notify(0, res.data.errors[0]);
            }
          });
      }
    },
    fetchProduct() {
      this.loading = true;
      util
        .http({
          url: "product/" + this.$props.id,
          method: "get",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            this.data = {
              name: res.data.data.name,
              description: res.data.data.description,
              price: res.data.data.price,
              status: res.data.data.status,
              sort: res.data.data.sort,
            };
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
  },
};
</script>
<style>
.title {
  background: #1d2b58 !important;
  color: #fff;
}
</style>
