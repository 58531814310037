<template>
  <div>
    <progress1 @cancelUpload="cancelUpload" :files="files" v-if="show" />
  </div>
</template>
<script>
import progress1 from "@/components/shared/progress";
import util from "@/util/util";
import axios from "axios";

export default {
  components: {
    progress1,
  },
  mounted() {
    util.event.$off("dropboxFilesUploaded");
    util.event.$on("dropboxFilesUploaded", (files, parent) => {
      this.show = true;
      console.log("Hi there");
      console.log(files, parent);
      this.$set(this, "files", []);
      this.$set(
        this,
        "files",
        Array.from(files).map((f) => {
          return { name: f.name, progress: 0, url: "", cancelRequest: false };
        })
      );
      this.upload(files, parent);
    });
  },
  data() {
    return {
      files: [],
      show: false,
    };
  },
  methods: {
    cancelUpload(file) {
      file.cancelRequest = true;
      axios({ url: file.url, method: "delete" });
    },
    async upload(files, parent) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let currentFile = this.files.find((f) => f.name == file.name);
        if (currentFile.cancelRequest) {
          continue;
        }
        let chunkCount = Math.ceil(file.size / util.CHUNK_SIZE.DROPBOX);
        let session = null;
        let baseurl = "https://content.dropboxapi.com/2/files/upload_session/";
        let headers = {
          Authorization: "Bearer " + this.$store.state.dropbox_token,
          "Content-Type": "application/octet-stream",
        };
        if (chunkCount == 1) {
          headers["Dropbox-API-Arg"] = JSON.stringify({
            autorename: false,
            mode: "add",
            mute: false,
            strict_conflict: false,
            path: (parent.path ? parent.path : "") + "/" + file.name,
          });
          let res = await axios({
            url: "https://content.dropboxapi.com/2/files/upload",
            headers,
            method: "POST",
            data: file.slice(0, file.size - 1),
          });
          currentFile.progress = 100;
          if (res.status == 200) {
            util.event.$emit("refreshFileList");
          }
        } else {
          for (let i = 0; i < chunkCount; i++) {
            if (currentFile.cancelRequest) {
              break;
            }

            if (i == 0) {
              session = await axios({
                url: baseurl + "start",
                headers,
                method: "POST",
                data: file.slice(
                  i * util.CHUNK_SIZE.DROPBOX,
                  (i + 1) * util.CHUNK_SIZE.DROPBOX
                ),
              });
              console.log(session);
            } else if (i == chunkCount - 1) {
              headers["Dropbox-API-Arg"] = JSON.stringify({
                cursor: {
                  session_id: session.data.session_id,
                  offset: i * util.CHUNK_SIZE.DROPBOX,
                },
                commit: {
                  path: (parent.path ? parent.path : "") + "/" + file.name,
                  mode: "overwrite",
                  autorename: false,
                  mute: false,
                },
              });
              let res = await axios({
                url: baseurl + "finish",
                headers,
                method: "POST",
                data: file.slice(
                  i * util.CHUNK_SIZE.DROPBOX,
                  (i + 1) * util.CHUNK_SIZE.DROPBOX
                ),
              });
              if (res.status == 200) {
                util.event.$emit("refreshFileList");
              }
            } else {
              let url = baseurl + "append";
              headers["Dropbox-API-Arg"] = JSON.stringify({
                session_id: session.data.session_id,
                offset: i * util.CHUNK_SIZE.DROPBOX,
              });

              await axios({
                url,
                headers,
                method: "POST",
                data: file.slice(
                  i * util.CHUNK_SIZE.DROPBOX,
                  (i + 1) * util.CHUNK_SIZE.DROPBOX
                ),
              });
            }
            let per = Math.ceil(((i + 1) / chunkCount) * 100);
            currentFile.progress = per;
          }
        }
      }
    },
  },
};
</script>
<style>
.progress-wrapper {
  width: 320px;
  position: fixed;
  right: 0;
  bottom: 20px;
}
.upload-title {
  font-size: 1.5rem;
  font-weight: 500;
  background-color: #0c2759;
  color: white;
  font-weight: bold;
}
</style>