<template>
  <div>
    <component-layout title="Activity Logs">
      <vue-table
        :key="key"
        :url="table.url"
        :sn="false"
        :except="table.except"
        :headingTransformer="table.headingTransformer"
        :html="table.html"
        :valueTransformer="table.valueTransformer"
        :columnSortOrder="table.columnSortOrder"
        :additionalColumns="table.additionalColumns"
        :additionalColumnsTransformer="table.additionalColumnsTransformer"
      ></vue-table>
    </component-layout>
  </div>
</template>
<script>
import util from "@/util/util";
import VueTable from "../shared/vue-table.vue";
import ComponentLayout from "../component-layout.vue";
export default {
  components: {
    VueTable,
    ComponentLayout,
  },
  data() {
    return {
      key: 0,
      quotations: [],
      selectedQuotation: {},
      page: 0,
      last_page: 0,
      searchTerm: "",
      table: {
        url: "user-logs",
        except: [
          "tags",
          "user_type",
          "auditable_type",
          "auditable_id",
          "user_id",
          "url",
          "ip_address",
          "user_agent",
          "updated_at",
          "id",
          "created_at_formatted",
          "created_at",
          "old_values",
          "new_values",
        ],
        additionalColumns: ["data"],
        html: ["user", "data"],
        headingTransformer: function(val) {
          return util.camelCase(val);
        },
        columnSortOrder: {
          user: 1,
          event: 2,
          data: 3,
        },
        valueTransformer: function() {
          return {
            user: (row) => {
              return [
                {
                  item: row.user ? row.user.full_name : "",
                  handler: () => null,
                },
              ];
            },
          };
        },
        additionalColumnsTransformer: function() {
          return {
            data: (row) => {
              return [
                {
                  item:
                    "<code><pre>" +
                    JSON.stringify(row, null, 2) +
                    "</pre></code>",
                  handler: () => null,
                },
              ];
            },
          };
        },
      },
    };
  },
  mounted() {
    // this.fetchQuotations();
  },
};
</script>
