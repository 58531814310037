<template>
  <div>
    <component-layout title="Roles"
      ><template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-auto bg-btn" dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title
                class="text-decoration-none"
                v-ripple
                style="cursor: pointer"
                v-on:click="showAddModal()"
              >
                Add New Role
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <vue-table
        :key="key"
        :url="table.url"
        :except="table.except"
        :sn="false"
        :headingTransformer="table.headingTransformer"
        :valueTransformer="table.valueTransformer"
        :html="table.html"
        :additionalColumns="table.additionalColumns"
        :additionalColumnsTransformer="table.additionalColumnsTransformer"
      ></vue-table>
    </component-layout>
    <v-dialog v-model="dialog" width="600" :key="key">
      <v-card>
        <v-card-title class="title text-h5">
          <span v-if="roleId === 0"> ADD ROLE </span>
          <span v-else> EDIT ROLE </span>
        </v-card-title>
        <v-card-text class="py-5">
          <addRole :id="roleId" @closeDialog="closeDialog" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import util from "@/util/util";
import VueTable from "../shared/vue-table.vue";
import ComponentLayout from "../component-layout.vue";
import permissions from "./list-permissions.vue";
import addRole from "./add.vue";
import action from "./action.vue";
export default {
  components: {
    VueTable,
    ComponentLayout,
    addRole,
  },
  data() {
    return {
      key: 0,
      roleId: 0,
      dialog: false,
      table: {
        url: "/core/role",
        except: [
          "id",
          "created_at",
          "updated_at",
          "deleted_at",
          "guard_name",
          "level",
          'permissions'
        ],
        additionalColumns: ["Actions"],
        headingTransformer: function(val) {
          return util.camelCase(val);
        },
        html: ["permissions", "Actions"],
        valueTransformer: function() {
          return {
            permissions: (row) => {
              return [
                {
                  comp: permissions,
                  prop: {
                    data: row,
                  },
                },
              ];
            },
          };
        },
        additionalColumnsTransformer: function() {
          return {
            Actions: (row) => {
              return [
                {
                  comp: action,
                  prop: {
                    item: row,
                    delete: {
                      params: { name: "role", id: row.id },
                    },
                  },
                },
              ];
            },
          };
        },
      },
    };
  },
  mounted() {
    util.event.$on("reload-resource", () => {
      this.key++;
    });
    util.event.$on("edit-role", (role) => {
      this.showEditModal(role.id);
    });
  },
  methods: {
    showAddModal() {
      this.roleId = 0;
      this.dialog = true;
      this.key++;
    },
    showEditModal(id) {
      this.roleId = id;
      this.dialog = true;
      this.key++;
    },
    closeDialog() {
      this.dialog = false;
      this.key++;
    },
  },
};
</script>
