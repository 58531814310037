<template>
  <div>
    <v-form ref="form" lazy-validation>
      <div class="title text-h6 px-3">
        Add Product
      </div>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="data.name"
              label="Name"
              placeholder="Enter Name"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              v-model="data.description"
              label="Description"
              placeholder="Enter Product Description"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="data.price"
              label="Price"
              placeholder="Enter Price"
              :rules="[rules.required, rules.number]"
              v-bind="basic"
            ></v-text-field>
          </v-col> </v-row
        ><v-row>
          <v-col>
            <v-btn
              color="success"
              class="mr-4"
              v-on:click="storeProduct"
              elevation="2"
              large
              block
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  data() {
    return {
      data: {},
      rules: util.validation,
      basic: util.input.basic,
      loading: false,
    };
  },
  methods: {
    storeProduct() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        util
          .http({ url: "product", method: "post", data: this.data })
          .then((res) => {
            this.loading = false;
            if (res.data.status == 1) {
              util.notify(1, "Product Stored");
              this.$emit("closeDialog");
              this.data = {};
            } else {
              util.notify(0, res.data.errors[0]);
            }
          });
      }
    },
  },
};
</script>
<style>
.title {
  background: #1d2b58 !important;
  color: #fff;
}
</style>
