<template>
  <div class="py-5">
    <div class="d-flex">
      <v-select
        v-if="hasFilter"
        v-bind="basic"
        class="mr-3"
        style="width: 50px"
        label="Filter By"
        v-model="filterCriteria.status"
        :items="statusOptions"
        item-text="text"
        item-value="value"
      ></v-select>
      <v-text-field
        class="mr-3"
        v-bind="basic"
        style="width: 50px"
        label="Search"
        append-icon="search"
        value=""
        outlined
        v-model="filterCriteria.q"
      ></v-text-field>

      <slot name="menu"></slot>
    </div>
    <div
      class="d-flex mt-2"
      v-if="
        filterCriteria.status == 'Valid_Till' ||
          filterCriteria.status == 'Issue_Date'
      "
    >
      <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="mr-3"
            :value="filterCriteria.date_from"
            clearable
            label="Date From"
            readonly
            v-bind="{ ...basic, ...attrs }"
            v-on="on"
            @click:clear="filterCriteria.date_from = null"
            :rules="[util.validation.required]"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filterCriteria.date_from"
          @change="menu1 = false"
        ></v-date-picker>
      </v-menu>

      <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="mr-3"
            :value="filterCriteria.date_to"
            clearable
            label="Date To"
            readonly
            v-bind="{ ...basic, ...attrs }"
            v-on="on"
            @click:clear="filterCriteria.date_to = null"
            :rules="[util.validation.required]"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filterCriteria.date_to"
          @change="menu2 = false"
        ></v-date-picker>
      </v-menu>
    </div>
    <slot></slot>

    <br />
    <v-pagination
      v-if="hasPagination"
      :length="filterCriteria.length"
      v-model="filterCriteria.page"
    ></v-pagination>
  </div>
</template>
<script>
import util from "@/util/util";

export default {
  props: {
    hasFilter: {
      type: Boolean,
      default: true,
    },
    length: {
      type: Number,
    },
    filterCriteriaProps: {
      type: Object,
      default: () => {
        return {
          q: "",
          page: 1,
          length: 2,
        };
      },
    },
    hasPagination: {
      type: Boolean,
      default: true,
    },
    itemName: {
      type: String,
    },
  },
  data() {
    return {
      util,
      menu1: false,
      menu2: false,
      basic: util.input.basic,
      filterCriteria: this.filterCriteriaProps,
      statusOptions: [],
    };
  },
  watch: {
    filterCriteria: {
      handler(n) {
        this.$emit("filter", n);
      },
      deep: true,
    },
    length: function(n) {
      this.filterCriteria.length = n;
    },
  },
  mounted() {
    this.getFilterCriteria();
  },
  methods: {
    getFilterCriteria() {
      util
        .http({
          url: `filters/${this.$props.itemName}`,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.statusOptions = [];
            res.data.data.forEach((item) => {
              this.statusOptions.push({
                text: util.camelCase(item),
                value: item,
              });
            });
            console.log(this.statusOptions);
          }
        });
    },
  },
};
</script>
<style scoped>
.bg-btn {
  background: #1d2b58 !important;
}
</style>
