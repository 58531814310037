<template>
  <div>
    <div>
      <router-link
        :to="{
          name: 'AssignRole',
          params: { id: $props.item.id },
        }"
        class="nav-link-button"
        exact
        title="Assign"
        ><v-icon class="view-button" small>mdi-clipboard-text</v-icon>
      </router-link>

      <span>
        <v-icon @click="editUser" class="edit-button" small title="Edit">
          mdi-pencil
        </v-icon>
      </span>

      <span>
        <v-icon
          @click="deleteObject"
          class="delete-button"
          small
          title="Delete"
        >
          mdi-delete
        </v-icon>
      </span>
      <slot></slot>
      <v-dialog v-model="showModal" max-width="500px">
        <deleteDialog
          resource="user"
          :id="$props.delete.params.id"
          @cancel="cancel"
          @delete-success="deleteSuccess"
        />
      </v-dialog>
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
import deleteDialog from "@/components/shared/delete-modal";
export default {
  components: { deleteDialog },
  props: {
    delete: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    deleteObject() {
      this.showModal = true;
    },
    cancel() {
      this.showModal = false;
    },
    deleteSuccess() {
      this.showModal = false;
      util.notify(1, "User deleted");
      util.event.$emit("reload-resource", true);
    },
    editUser() {
      util.event.$emit("edit-user", this.$props.item);
    },
  },
};
</script>
<style scoped>
.fa-trash {
  color: #cc0000;
  cursor: pointer;
}
.view-button {
  color: #1d2b58 !important;
  cursor: pointer;
}
.delete-button {
  color: #f45353 !important;
  cursor: pointer;
}
.edit-button {
  color: #ffbf00 !important;
  cursor: pointer;
}
.fa-edit {
  color: #ffbf00;
}
.confirm-box {
  display: flex;
  justify-content: space-evenly;
}
.nav-link-button {
  display: inline !important;
  padding: 2px !important;
}
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87) !important ;
}
</style>
