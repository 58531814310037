<template>
  <div>
    <v-form v-model="isValid" ref="form">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            label="Name"
            v-model="data.name"
            v-bind="basic"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            label="Level"
            v-model="data.level"
            v-bind="basic"
            :rules="[rules.required, rules.number]"
          />
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-btn
            color="primary"
            @click="submit"
            :disabled="!isValid"
            :loading="loading"
          >
            <span v-if="$props.id === 0">Create Role</span>
            <span v-else>Update Role</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import util from "@/util/util";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      rules: util.validation,
      isValid: false,
      basic: util.input.basic,
      data: {},
    };
  },
  mounted() {
    if (this.$props.id !== 0) {
      this.fetchRole();
    }
  },
  methods: {
    fetchRole() {
      util
        .http({
          url: "/core/role/" + this.$props.id,
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.data = res.data.data;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    submit() {
      this.loading = true;
      let url =
        this.$props.id === 0 ? "/core/role" : `/core/role/${this.$props.id}`;
      util
        .http({
          url: url,
          method: this.$props.id === 0 ? "POST" : "PUT",
          data: this.data,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            util.notify(
              1,
              this.$props.id === 0 ? "Role Created" : "Role Updated"
            );
            this.$emit("closeDialog", true);
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
  },
};
</script>
<style scoped>
/* .role-wrapper {
  padding-top: 30px;
} */
.item-button {
  background: #1d2b58 !important;
  color: #fff !important;
}
</style>
