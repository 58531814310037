<template>
  <div>
    <progress1 @cancelUpload="cancelUpload" :files="files" v-if="show" />
  </div>
</template>
<script>
import progress1 from "@/components/shared/progress";
import util from "@/util/util";
import axios from "axios";

export default {
  components: {
    progress1,
  },
  mounted() {
    util.event.$off("filesUploaded");
    util.event.$on("filesUploaded", (files, parent) => {
      this.show = true;
      console.log("Hi there");
      console.log(files, parent);
      this.$set(this, "files", []);
      this.$set(
        this,
        "files",
        Array.from(files).map((f) => {
          return { name: f.name, progress: 0, url: "", cancelRequest: false };
        })
      );
      this.upload(files, parent);
    });
  },
  data() {
    return {
      files: [],
      show: false,
    };
  },
  methods: {
    cancelUpload(file) {
      file.cancelRequest = true;
      axios({ url: file.url, method: "delete" });
    },
    async upload(files, parent) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let currentFile = this.files.find((f) => f.name == file.name);
        if (currentFile.cancelRequest) {
          continue;
        }
        let resp = await axios({
          url:
            "https://graph.microsoft.com/v1.0/me/drive/items/" +
            parent.id +
            ":/" +
            file.name +
            ":/createUploadSession",
          method: "POST",
          headers: {
            Authorization: "Bearer " + this.$store.state.one_drive_token,
          },
          data: {
            item: {
              "@odata.type": "microsoft.graph.driveItemUploadableProperties",
              name: file.name,
            },
          },
        });
        if (resp.data.uploadUrl) {
          let url = resp.data.uploadUrl;
          currentFile.url = resp.data.uploadUrl;
          console.log(url);
          let chunkCount = Math.ceil(file.size / util.CHUNK_SIZE.ONEDRIVE);
          for (let i = 0; i < chunkCount; i++) {
            if (currentFile.cancelRequest) {
              break;
            }
            let resp = await axios({
              url,
              method: "put",
              headers: {
                Authorization: `Bearer ${this.$store.state.one_drive_token}`,
                "Content-Range":
                  "bytes " +
                  i * util.CHUNK_SIZE.ONEDRIVE +
                  "-" +
                  ((i + 1) * util.CHUNK_SIZE.ONEDRIVE - 1 > file.size
                    ? file.size - 1
                    : (i + 1) * util.CHUNK_SIZE.ONEDRIVE - 1) +
                  "/" +
                  file.size,
              },
              data: file.slice(
                i * util.CHUNK_SIZE.ONEDRIVE,
                (i + 1) * util.CHUNK_SIZE.ONEDRIVE
              ),
            });
            console.log("sent file" + file.name);
            let per = Math.ceil(((i + 1) / chunkCount) * 100);
            currentFile.progress = per;
            if (resp.status == 201 || resp.status == 200) {
              util.event.$emit("refreshFileList");
            }
          }
        }
      }
    },
  },
};
</script>
<style>
.progress-wrapper {
  width: 320px;
  position: fixed;
  right: 0;
  bottom: 20px;
}
.upload-title {
  font-size: 1.5rem;
  font-weight: 500;
  background-color: #0c2759;
  color: white;
  font-weight: bold;
}
</style>