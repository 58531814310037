<template>
  <div>
    <component-layout title="Products">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-ripple="{ center: true }"
              class="route-button"
              @click="showAddProduct()"
            >
              ADD
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <vue-table
        itemName="product"
        :key="key"
        :url="table.url"
        :except="table.except"
        :sn="false"
        :headingTransformer="table.headingTransformer"
        :html="table.html"
        :valueTransformer="table.valueTransformer"
        :additionalColumns="table.additionalColumns"
        :additionalColumnsTransformer="table.additionalColumnsTransformer"
      ></vue-table>
    </component-layout>
    <v-dialog v-model="showAddProductDialog" max-width="600px" :key="key + 'a'">
      <addProduct @closeDialog="closeDialog" />
    </v-dialog>
    <v-dialog
      v-model="showEditProductDialog"
      max-width="600px"
      :key="key + 'b'"
    >
      <editProduct @closeDialog="closeDialog" :id="productId" />
    </v-dialog>
  </div>
</template>
<script>
import util from "@/util/util";
import VueTable from "../shared/vue-table.vue";
import ComponentLayout from "../component-layout.vue";
import addProduct from "./create.vue";
import editProduct from "./edit.vue";
import action from "./action";
export default {
  components: {
    VueTable,
    ComponentLayout,
    addProduct,
    editProduct,
  },
  data() {
    return {
      key: 0,
      productId: 0,
      searchTerm: "",
      showAddProductDialog: false,
      showEditProductDialog: false,
      table: {
        url: "product",
        except: [
          "id",
          "created_at",
          "updated_at",
          "deleted_at",
          "sort",
          "price_formatted",
        ],
        html: ["status", "price", "Actions"],
        additionalColumns: ["Actions"],
        headingTransformer: function(val) {
          return util.camelCase(val);
        },
        valueTransformer: function() {
          return {
            status: (row) => {
              return [
                {
                  item:
                    '<span style="color:white;padding:0.4rem" class="badge ' +
                    (row.status == "Inactive"
                      ? "new badge red"
                      : "new badge green") +
                    '">' +
                    (row.status == "Inactive" ? "Not Active" : "Active") +
                    "</span>",
                  handler: () => null,
                },
              ];
            },
            price: (row) => {
              return [
                {
                  item: row.price_formatted,
                  handler: () => null,
                },
              ];
            },
          };
        },
        additionalColumnsTransformer: function() {
          return {
            Actions: (row) => {
              return [
                {
                  comp: action,
                  prop: {
                    item: row,
                    delete: {
                      params: { name: "product", id: row.id },
                    },
                  },
                },
              ];
            },
          };
        },
      },
    };
  },
  mounted() {
    util.event.$on("reload-resource", () => {
      this.key++;
    });
    util.event.$on("edit-product", (product) => {
      this.showEditModal(product.id);
    });
  },
  methods: {
    showAddProduct() {
      this.showAddProductDialog = true;
    },
    showEditModal(id) {
      this.productId = id;
      this.key++;
      this.showEditProductDialog = true;
    },
    closeDialog() {
      this.showAddProductDialog = false;
      this.showEditProductDialog = false;
      this.key++;
    },
  },
};
</script>
<style>
.route-button {
  cursor: pointer !important;
}
</style>
