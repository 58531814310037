<template>
  <div>
    <component-layout title="Assign Permissions">
      <v-form>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              label="Name"
              v-model="role.name"
              v-bind="basic"
              disabled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-progress-linear
            v-if="listLoading"
            indeterminate
            color="deep-purple accent-4"
            class="mb-1"
          ></v-progress-linear>
        </v-row>

        <v-checkbox
          label="All Permissions"
          color="success"
          v-model="all"
          @change="selectAll"
        ></v-checkbox>
        <div class="row">
          <div class="col-md-3" v-for="p in allPermissions" :key="p.id">
            <v-checkbox
              :label="p.description"
              :value="p.id"
              color="success"
              v-model="permissions"
            ></v-checkbox>
          </div>
        </div>
        <v-col cols="12" md="12" sm="12">
          <v-btn color="primary" @click="submit" :loading="loading">
            <span>Submit</span>
          </v-btn>
        </v-col>
      </v-form>
    </component-layout>
  </div>
</template>
<script>
import util from "@/util/util";
import ComponentLayout from "../component-layout.vue";
export default {
  components: {
    ComponentLayout,
  },
  data() {
    return {
      loading: false,
      listLoading: false,
      basic: util.input.basic,
      all: false,
      role: {},
      allPermissions: [],
      permissions: [],
    };
  },
  mounted() {
    this.fetchRole();
    this.fetchPermissions();
    this.fetchAssignedPermissions();
  },
  methods: {
    fetchRole() {
      util
        .http({
          url: "/core/role/" + this.$route.params.id,
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.role = res.data.data;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    fetchPermissions() {
      this.listLoading = true;
      util
        .http({
          url: "/core/role/get-all-permissions",
        })
        .then((res) => {
          this.listLoading = false;
          if (res.data.status == 1) {
            this.allPermissions = res.data.data;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    fetchAssignedPermissions() {
      util
        .http({
          url: "/core/role/get-permissions/" + this.$route.params.id,
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.permissions = res.data.data;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    submit() {
      this.loading = true;
      util
        .http({
          url: "/core/role/assign-permissions/" + this.$route.params.id,
          method: "post",
          data: { permissions: this.permissions },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            util.notify(1, "Permissions Assigned");
            this.$router.push({ name: "role-list" });
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    selectAll() {
      if (this.all) {
        this.permissions = this.allPermissions.map((p) => {
          return p.id;
        });
      } else {
        this.permissions = [];
      }
    },
  },
  watch: {
    permissions: function() {
      for (let i = 0; i < this.allPermissions.length; i++) {
        if (this.permissions.indexOf(this.allPermissions[i].id) == -1) {
          this.all = false;
          break;
        } else {
          this.all = true;
        }
      }
    },
  },
};
</script>
