<template>
  <div>
    <component-layout title="Assign Role">
      <v-form>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              label="Name"
              v-model="user.full_name"
              v-bind="basic"
              disabled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-progress-linear
            v-if="listLoading"
            indeterminate
            color="deep-purple accent-4"
            class="mb-1"
          ></v-progress-linear>
        </v-row>

        <v-col cols="12" md="12" sm="12" class="d-flex flex-wrap">
          <div v-for="r in allRoles" :key="r.id" class="check-box_options">
            <v-checkbox
              :label="r.name"
              :value="r.id"
              color="success"
              v-model="roles"
            ></v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-btn color="primary" @click="submit" :loading="loading">
            <span>Submit</span>
          </v-btn>
        </v-col>
      </v-form>
    </component-layout>
  </div>
</template>
<script>
import util from "@/util/util";
import ComponentLayout from "../component-layout.vue";
export default {
  components: {
    ComponentLayout,
  },
  data() {
    return {
      basic: util.input.basic,
      all: false,
      user: {},
      allRoles: [],
      roles: [],
      loading: false,
      listLoading: false,
    };
  },
  mounted() {
    this.fetchUser();
    this.fetchRoles();
    this.fetchAssignedRoles();
  },
  methods: {
    fetchUser() {
      this.listLoading = true;
      util
        .http({
          url: "/user/" + this.$route.params.id,
        })
        .then((res) => {
          this.listLoading = false;
          if (res.data.status == 1) {
            this.user = res.data.data;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    fetchRoles() {
      util
        .http({
          url: "/core/role/get-all",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.allRoles = res.data.data;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    fetchAssignedRoles() {
      util
        .http({
          url: "/core/role/get-role/" + this.$route.params.id,
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.roles = res.data.data;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    submit() {
      this.loading = true;
      util
        .http({
          url: "/core/role/assign-role/" + this.$route.params.id,
          method: "post",
          data: { roles: this.roles },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            util.notify(1, "Roles Assigned");
            this.$router.push({ name: "user-list" });
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
  },
  watch: {
    roles: function() {
      for (let i = 0; i < this.allRoles.length; i++) {
        if (this.roles.indexOf(this.allRoles[i].id) == -1) {
          this.all = false;
          break;
        } else {
          this.all = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.check-box_options:not(:first-child) {
  margin-left: 1.2rem;
}
</style>
